@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 20px;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.18em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    padding: 5px;
}

.container-lg {
    background-color: #fefefe;
    padding: 10px;
    border-style: solid;
    border-color: #04585E;
}

.card {
    width: 70rem;
    padding: 5px;
    align-content: center;
    margin: 10px;
}

.quote {
  background: #04585E;
  color: lightgray;
  padding: 1em;
  border-radius: 1em;
  max-width: 550px;
  font-size: 1.3em;
  line-height: 1.25em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  margin: 0 auto;
  margin-bottom: 40px;
  opacity: 0.8;
}

.quote::after {
  background-image: url(Images/background.jpg);
}

.quote figcaption,
.quote blockquote {
  color: white;
  font-style: italic;
}

cite {
  font-style: normal;
  font-size: 0.8em;

}

.center-image 
{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
}

.left-float-image
{
  float: left;
}

.right-float-image
{
  float: right;
}

.figure-image {
  display: block;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
  border: 5px solid #555;
  border-radius: 1%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

img.align-left {
  float: left;    
  margin: 0 15px 0 0;
}

img.align-right {
  float: right;    
  margin: 0 15px 0 0;
}

section {
  padding: 10px;
  clear: both;
}

table {
  background-color: #000000;
  color: #ffffff;
}

table th, td {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

table td {
  color: #000000;
}

tbody tr:nth-child(odd) {
  background-color: #bbbbbb;
}

tbody tr:nth-child(even) {
  background-color: #cccccc;
}

header {
  border: 1px dotted black;
  height: 100px;
  margin: 15px;
  background-color: #ffffdd;
}

/* main {
  border: 1px dotted black;
  margin: 15px;
} */

figure {
  float: left;
  width: 30%;
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  border: thin silver solid;
}

h1 {
  font-weight: 600;
  font-size: 1.8em;
}

h2 {
  clear: both;
  font-weight: 600;
  font-size: 1.4em;
  color:rgba(0, 0, 0, 0.8)
}

h3 {
  font-weight: 600;
  font-size: 1.1em;
  color:rgba(0, 0, 0, 0.8)
}

section h2 {
  clear: none;
}

span{
    color: red;
}

.intro {
  font-size: 1.2em;
  line-height: 1.2em;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
}

section h6 {
  text-align: center;
  font-style: italic;
  margin-bottom: 18px;
}

.box {
  display: flex;
  align-items:center;  
}

.box span {
  color: black;
  padding: 15px;
}

.member-photo
{
  margin: 20px;
  border: 5px solid white;
  border-radius: 1%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  max-width: 35%;
}

.supporter-logo 
{
  background-color: white;
  color: black;
  padding: 5px;
  padding-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.supporter-logo img
{
  max-width: 45%;
}

.supporter-logo div 
{
  text-align: center;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: bold;
}

.report-table 
{
  margin-left: auto;
  margin-right: auto;
}

.mental-health-page img
{
  display: block;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
  border: 5px solid #555;
  border-radius: 1%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.driving-and-travel-page img
{
  display: block;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
  border: 5px solid #555;
  border-radius: 1%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
