nav {
  background-color: #04585E;
  margin-top: 5px;
  margin-bottom: 5px;
}

li.nav-item > a {
  color: white;
}

li.nav-item > a:hover {
  color: white;
}

button.navbar-toggler {
  padding: 0px;
  margin: 0px;
}

.navbar-toggler span {
  color: white;
  font-size: 0.9em;
  font-weight: bold;
}
