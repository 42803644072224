.hero {
    height: 130px;
    background-size: cover;
    background-position: center;
    position: relative;
    background-color: #DEB703;
    padding: 10px;
}

#logo {
    height: 80px;
    display: block;
    margin: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 45%;
    border: 5px solid #555;
    border-radius: 1%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
