#grid {
  margin-left: auto;
  margin-right: auto;  
  width: 500px;
  height: 200px;
  text-align: center;
  background-color: white;
  border-collapse: separate;
  border-spacing: 5px 5px  
}

td {
  padding: 5px;
  background-color: white;
}

td.shaded1 {
  padding: 5px;
  background-color: #DEB703;
}

td.shaded2 {
  padding: 5px;
  background-color: #04585E;
}